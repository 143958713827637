.buttonsHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-family: "Avenir-Roman;";
}

.circleButton {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 6px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
  cursor: pointer;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}

.circleButton.enabled {
  border: 3px solid #009bdb;
  background-color: #009bdb;
}

.circleButton.current {
  pointer-events: none;
  border: 3px solid #009bdb;
}

.circleButton.disabled {
  border: 3px solid #ffffff;
  pointer-events: none;
  opacity: 0.5;
}

.circleButton:hover {
  border: 3px solid #ffffff;
}

.elpNavigatorContainer {
  position: absolute;
  display: flex;
}

.elpNavButton {
  display: block;
  width: 79px;
  height: 23px;
  margin-right: 9px;
  margin-left: 9px;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Heavy";
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.elpNavButton.current {
  display: flex;
  opacity: 0.502;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33), inset 0px 3px 7px 0px rgba(0, 0, 0, 0.76);
}

.elpNavButton.enabled {
  display: flex;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.30196) 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0.502;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}
.elpNavButton.disabled {
  display: flex;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.30196) 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0.3;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
  pointer-events: none;
}

.bintColorPickerContainer {
  position: absolute;
  display: flex;
  top: 539px;
}

.bintColorPickerBG {
  display: block;
  /* width: 79px; */
  height: 23px;
  margin-right: 9px;
  margin-left: 9px;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Roman";
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33), inset 0px 3px 7px 0px rgba(0, 0, 0, 0.76);
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bintColorPickerBG.color {
  padding-left: 12px;
}

.bintColorPickerInner {
  transform: scale(0.75);
}
