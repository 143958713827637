body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.confetti {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50px;
  top: 50px;
  /* background: blue; */
}

*,
*::before,
*::after {
  font-family: "Avenir-Roman";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 62.5%; */
}

html {
  font-size: 100%;
}

:root {
  --primary: #d81e5b;
  --secondary: #8c38ff;
  --dark: #131a26;
  --light: #eee;
  --neutral: #e4e4ee;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  --standard-box-shadow-color: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"), url(/static/media/Avenir-Roman-12.c6d37b26.ttf) format("truetype");
  /* font-weight:400 ; */
}

@font-face {
  font-family: "Avenir-Heavy";
  src: local("Avenir-Heavy"), url(/static/media/Avenir-Heavy-05.8adaa770.ttf) format("truetype");
  /* font-weight:400 ; */
}

.titleText {
  font-family: "Avenir-Heavy";
  font-size: 1rem;
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
}

.standardText {
  font-family: "Avenir-Roman";
  font-size: 1.8rem;
  color: white;
}

.appContainer {
  width: 1280px;
  height: 720px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px white solid; */
  font-family: "Avenir-Roman";
  box-sizing: border-box;
}

.bgImageContainer {
  background-image: url(/static/media/bg3.509f295a.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  position: absolute;
  top: 0px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.topSub .logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.topSub {
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.contentSub {
  width: 100%;
  height: 398px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navSub {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5%;
  flex-direction: column;
}

.productPage {
  display: none;
  width: 100%;
  height: 100%;
}

.productPageActive {
  display: block;
}

.elevatorPage {
  width: 524px;
  height: 393px;
}

/* //////////////////////////
////////////////  PAGES (step 2)
///////////////////////////
*/

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page.hide {
  display: none;
}

.inputsPage {
  display: block;
  padding-left: 20%;
  padding-right: 20%;
}

.adBuildingPage .platformButtonContainer {
  width: 89px;
  height: 100%;
}

.adBuildingPageContent.elevator {
  width: 640px;
  height: 480px;
  -webkit-transform: scale(0.82);
          transform: scale(0.82);
}

.adBuildingPageContent.landscape {
  width: 1280px;
  height: 720px;
  -webkit-transform: scale(0.5527);
          transform: scale(0.5527);
}

.adBuildingPageContent.portrait {
  width: 720px;
  height: 1280px;
  -webkit-transform: scale(0.3109);
          transform: scale(0.3109);
}

.adBuildingPageInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.adBuildingPageInner.hide {
  display: none;
}

.ebintAd.hide {
  display: none;
}

.lBintAd.hide {
  display: none;
}

.pBintAd.hide {
  display: none;
}

.platformButton {
  border-radius: 5px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
  box-shadow: var(--standard-box-shadow-color);
  width: 100%;
  height: 54px;
  font-family: "Avenir-Heavy";
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.platformButtonOn {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.platformButtonDisabled {
  display: none;
}

.colorPicker {
  position: absolute;
  top: 0px;
  left: 190px;
  /* transform: scale(.8); */
  /* transform-origin: top left; */
}
.buttonsHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-family: "Avenir-Roman;";
}

.circleButton {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 6px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
  cursor: pointer;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}

.circleButton.enabled {
  border: 3px solid #009bdb;
  background-color: #009bdb;
}

.circleButton.current {
  pointer-events: none;
  border: 3px solid #009bdb;
}

.circleButton.disabled {
  border: 3px solid #ffffff;
  pointer-events: none;
  opacity: 0.5;
}

.circleButton:hover {
  border: 3px solid #ffffff;
}

.elpNavigatorContainer {
  position: absolute;
  display: flex;
}

.elpNavButton {
  display: block;
  width: 79px;
  height: 23px;
  margin-right: 9px;
  margin-left: 9px;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Heavy";
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.elpNavButton.current {
  display: flex;
  opacity: 0.502;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33), inset 0px 3px 7px 0px rgba(0, 0, 0, 0.76);
}

.elpNavButton.enabled {
  display: flex;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.30196) 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0.502;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}
.elpNavButton.disabled {
  display: flex;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.30196) 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0.3;
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
  pointer-events: none;
}

.bintColorPickerContainer {
  position: absolute;
  display: flex;
  top: 539px;
}

.bintColorPickerBG {
  display: block;
  /* width: 79px; */
  height: 23px;
  margin-right: 9px;
  margin-left: 9px;
  font-size: 12px;
  color: white;
  font-family: "Avenir-Roman";
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%);
  box-shadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33), inset 0px 3px 7px 0px rgba(0, 0, 0, 0.76);
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.bintColorPickerBG.color {
  padding-left: 12px;
}

.bintColorPickerInner {
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}

.elevatorProductContainer,
.landscapeProductContainer,
.portraitProductContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  object-fit: scale-down;
}

.eHSContentNarrow {
  width: 75%;
  height: 32.9%;
  z-index: 100;
}

.eFullScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.pageContainer {
  width: 100%;
  height: 100%;
}

.backgroundImageContainer {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 2px;
}

.backgroundImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* **************** Landscape Windows ****************** */

.lHSContent {
  width: 300px;
  height: 120px;
  position: absolute;
  top: 187px;
  left: 140px;
}

.dropZone {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  /* padding: 2px; */
}

.droppedImageHolder {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 150;
  pointer-events: none;
}

.droppedImageHolder img {
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageGrandParent {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageParent {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageParent video {
  width: 100%;
  height: 100%;
}

.dropzoneHint {
  position: absolute;
  color: white;
  top: 10px;
  left: 10px;
  font-size: 1.3rem;
  z-index: 3000;
}

.shakeIt {
  -webkit-animation: shake 0.75s;
          animation: shake 0.75s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

input {
  box-sizing: border-box;
}

.inputsPage input[type="text"] {
  font-size: 18px;
  width: 390px;
  height: 37px;
  padding-left: 10px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  display: block;
}

input[type="text"].textInputErrorOutline {
  /* border: 1px red solid; */
  box-shadow: 0px 0px 8px 1px #0ff;
}
.textInputErrorShake {
  -webkit-animation: shake 0.75s;
          animation: shake 0.75s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.textInputErrorOutline {
  /* border: 1px red solid; */
  /* background: linear-gradient(180deg, rgba(0,0,0,.6) 100% rgba(0,0,0,.2)0); */
  box-shadow: 0px 0px 8px 1px #0ff;
}
input:hover,
input:focus {
  border: 1px #009bdb solid;
}

.bintColorPickerContainer input:hover,
input:focus {
  border: none;
}

.inputsPage input,
.inputsPage label {
  margin-bottom: 10px;
}

.inputsFullPage {
  width: 100%;
  height: 100%;
  display: flex;
}

.inputsLeftColumn {
  width: 62%;
}

.inputsRightColumn {
  width: 38%;
}

input:focus {
  outline-width: 0;
}

.inputsLeftColumn.shakeIt {
  -webkit-animation: shake 0.75s;
          animation: shake 0.75s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
            transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
            transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
            transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
            transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
            transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
            transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
            transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
            transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
            transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
            transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
            transform: translate(1px, -2px) rotate(-1deg);
  }
}

.warningMessage {
  color: white;
  transition: color 1s;
}
.warningMessageAnimation {
  -webkit-animation: fadeOutRed 1.5s;
          animation: fadeOutRed 1.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes fadeOutRed {
  0% {
    color: red;
  }
  10% {
    color: red;
  }
  100% {
    color: white;
  }
}

@keyframes fadeOutRed {
  0% {
    color: red;
  }
  10% {
    color: red;
  }
  100% {
    color: white;
  }
}

.buttonStyle {
  color: white;
  font-size: 1.3em;

  width: 50%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  border: none;
  border-top: gray 1px solid;
  transition: 0.4s;
}

#buttonLeft {
  border-right: gray 1px solid;
}

.buttonStyle:hover {
  background: rgba(255, 255, 255, 0.3);
}

