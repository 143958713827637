.dropZone {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  /* padding: 2px; */
}

.droppedImageHolder {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 150;
  pointer-events: none;
}

.droppedImageHolder img {
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageGrandParent {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageParent {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dropzoneImageParent video {
  width: 100%;
  height: 100%;
}

.dropzoneHint {
  position: absolute;
  color: white;
  top: 10px;
  left: 10px;
  font-size: 1.3rem;
  z-index: 3000;
}

.shakeIt {
  animation: shake 0.75s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
