*,
*::before,
*::after {
  font-family: "Avenir-Roman";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 62.5%; */
}

html {
  font-size: 100%;
}

:root {
  --primary: #d81e5b;
  --secondary: #8c38ff;
  --dark: #131a26;
  --light: #eee;
  --neutral: #e4e4ee;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  --standard-box-shadow-color: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"), url("./assets/fonts/Avenir-Roman-12.ttf") format("truetype");
  /* font-weight:400 ; */
}

@font-face {
  font-family: "Avenir-Heavy";
  src: local("Avenir-Heavy"), url("./assets/fonts/Avenir-Heavy-05.ttf") format("truetype");
  /* font-weight:400 ; */
}

.titleText {
  font-family: "Avenir-Heavy";
  font-size: 1rem;
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
}

.standardText {
  font-family: "Avenir-Roman";
  font-size: 1.8rem;
  color: white;
}

.appContainer {
  width: 1280px;
  height: 720px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px white solid; */
  font-family: "Avenir-Roman";
  box-sizing: border-box;
}

.bgImageContainer {
  background-image: url("./assets/bg3.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  position: absolute;
  top: 0px;
  transform: scale(0.5);
}

.topSub .logoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.topSub {
  width: 100%;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.contentSub {
  width: 100%;
  height: 398px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navSub {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5%;
  flex-direction: column;
}

.productPage {
  display: none;
  width: 100%;
  height: 100%;
}

.productPageActive {
  display: block;
}

.elevatorPage {
  width: 524px;
  height: 393px;
}

/* //////////////////////////
////////////////  PAGES (step 2)
///////////////////////////
*/

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page.hide {
  display: none;
}

.inputsPage {
  display: block;
  padding-left: 20%;
  padding-right: 20%;
}

.adBuildingPage .platformButtonContainer {
  width: 89px;
  height: 100%;
}

.adBuildingPageContent.elevator {
  width: 640px;
  height: 480px;
  transform: scale(0.82);
}

.adBuildingPageContent.landscape {
  width: 1280px;
  height: 720px;
  transform: scale(0.5527);
}

.adBuildingPageContent.portrait {
  width: 720px;
  height: 1280px;
  transform: scale(0.3109);
}

.adBuildingPageInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.adBuildingPageInner.hide {
  display: none;
}

.ebintAd.hide {
  display: none;
}

.lBintAd.hide {
  display: none;
}

.pBintAd.hide {
  display: none;
}

.platformButton {
  border-radius: 5px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%);
  box-shadow: var(--standard-box-shadow-color);
  width: 100%;
  height: 54px;
  font-family: "Avenir-Heavy";
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  user-select: none;
}

.platformButtonOn {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.platformButtonDisabled {
  display: none;
}

.colorPicker {
  position: absolute;
  top: 0px;
  left: 190px;
  /* transform: scale(.8); */
  /* transform-origin: top left; */
}