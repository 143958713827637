input {
  box-sizing: border-box;
}

.inputsPage input[type="text"] {
  font-size: 18px;
  width: 390px;
  height: 37px;
  padding-left: 10px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  display: block;
}

input[type="text"].textInputErrorOutline {
  /* border: 1px red solid; */
  box-shadow: 0px 0px 8px 1px #0ff;
}
.textInputErrorShake {
  animation: shake 0.75s;
  animation-iteration-count: 1;
}
.textInputErrorOutline {
  /* border: 1px red solid; */
  /* background: linear-gradient(180deg, rgba(0,0,0,.6) 100% rgba(0,0,0,.2)0); */
  box-shadow: 0px 0px 8px 1px #0ff;
}
input:hover,
input:focus {
  border: 1px #009bdb solid;
}

.bintColorPickerContainer input:hover,
input:focus {
  border: none;
}

.inputsPage input,
.inputsPage label {
  margin-bottom: 10px;
}

.inputsFullPage {
  width: 100%;
  height: 100%;
  display: flex;
}

.inputsLeftColumn {
  width: 62%;
}

.inputsRightColumn {
  width: 38%;
}

input:focus {
  outline-width: 0;
}

.inputsLeftColumn.shakeIt {
  animation: shake 0.75s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
