.elevatorProductContainer,
.landscapeProductContainer,
.portraitProductContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  object-fit: scale-down;
}

.eHSContentNarrow {
  width: 75%;
  height: 32.9%;
  z-index: 100;
}

.eFullScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.pageContainer {
  width: 100%;
  height: 100%;
}

.backgroundImageContainer {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 2px;
}

.backgroundImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* **************** Landscape Windows ****************** */

.lHSContent {
  width: 300px;
  height: 120px;
  position: absolute;
  top: 187px;
  left: 140px;
}
